.navbar {
  font-size: 17px;
  background-color: #ffffff;
  border-bottom: 1px solid #0b2d4d; }
  .navbar a {
    color: #0b2d4d !important; }
  @media (max-width: 768px) {
    .navbar .expanded-nav {
      display: none; } }
  @media (min-width: 768px) {
    .navbar .collapsed-nav {
      display: none; } }
  .navbar .navbar-right ul.login-logout {
    padding: 10px 15px; }
    .navbar .navbar-right ul.login-logout .btn {
      margin-bottom: 10px; }
  .navbar .dropdown-menu > li > a {
    margin-left: 0; }
  .navbar .alert {
    padding: 5px 10px;
    line-height: 20px; }
  .navbar .navbar-nav > li {
    min-width: 50px; }
    .navbar .navbar-nav > li:hover {
      border-bottom: 3px solid #0b2d4d; }
    .navbar .navbar-nav > li.active {
      border-bottom: 3px solid #0b2d4d; }
      .navbar .navbar-nav > li.active a {
        background-color: #ffffff !important; }
    .navbar .navbar-nav > li .menu-item-home {
      background-image: url(../images/spritesheet.png);
      background-position: -102px -246px;
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-top: -4px;
      margin-bottom: -6px; }
  .navbar ul.login-logout {
    width: 500px; }
    .navbar ul.login-logout li > div {
      width: 40%;
      float: left;
      padding-right: 10px; }
    .navbar ul.login-logout li > form {
      width: 60%;
      float: right;
      padding: 10px; }
  .navbar .navbar-nav > li > a {
    cursor: pointer; }

@media (max-width: 768px) {
  body {
    padding-top: 290px; } }

@media (min-width: 768px) {
  body {
    padding-top: 153px; } }

/*# sourceMappingURL=../../../../../../../static/hrs_pp/css/navbar.css.map */